@import "src/components/palette.module.scss";

.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  //background: rgba(0,0,0,0.03);

  :global {
    .MuiOutlinedInput-root {
      width: 100%;
    }
    .MuiOutlinedInput-root.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $-brand-color;
    }
    label.Mui-focused {
      color: $-brand-color;
    }

  }
}

.input:global(.MuiFormControl-root) {
  margin-bottom: 20px;

}



.confirmation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confirmationIcon {
  color: $-brand-color;

  font-size: 120px;

}


.emailPreview {
  margin-bottom: 20px;
  color: rgba(0,0,0,0.3);
  cursor: pointer;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    color: $-brand-color;
    text-decoration: underline;
  }
}
.orBlock {
  position: relative;
  width: 100%;
  text-align: center;
  margin: 20px 0;
  color: rgba(0,0,0,0.3);
  display: flex;

  span {
    position: relative;
    z-index: 2;
    padding: 0 10px;
    text-transform: lowercase;
  }

  i {
    //background: red;
    display: flex;
    flex: 1 1 100%;
    position: relative;
    &:before {
      display: flex;
      content: '';
      height: 1px;
      width: 100%;
      background-color: rgba(0,0,0,0.2);
      position: absolute;
      top: 11px;
    }

  }
}
//.enterButton:global(.MuiButton-root) {
//  background: $-brand-color;
//  &:hover {
//    background: $-brand-color;
//    filter: saturate(1.1);
//  }
//  color: #fff;
//}

.helpText {
  margin: 0;
  color: $-grey-dark;
  margin-bottom: 10px;
}

.confirmationCodeButton:global(.MuiButton-root) {
  margin-top: 20px;
}

.LoginRegistrationForm {

}