@import "src/components/palette.module.scss";

.component {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.input {
  width: 40px;
  height: 40px;
  text-align: center;
  border: 0;
  border-left: 1px solid #ddd;
  outline: none;
  &:first-child {
    border-left: 0;
  }
}

:global(.glass-theme) {

  .component {
    input {
      @include glass-item-background;
    }
  }


}