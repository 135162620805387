@import "src/components/palette.module.scss";

:global(.glass-theme) {
  .isContent {
    &:before{
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top:0;
      width: 100%;
      height: 100%;
      background: rgb(255,255,255, $-glass-main);
    }
  }
}