@import "src/components/palette.module.scss";

.component {
  position: relative;
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 10px;
  color: $-text-additional;
  background-color: #e6e6e6;
  overflow: hidden;
  width: 100%;
  padding: 0 5px;

  input {
    border: 0;
    outline: 0;
    padding: 0 12px;
    height: 32px;
    display: flex;
    flex: 1;
    background-color: inherit;
  }
  svg {
    fill: #999999
  }
}

.searchIcon {
  position: absolute;
  left: 0;
}

.clearIcon {
  cursor: pointer;
  &:hover {
    fill: #817f7f;
  }
}


:global(.glass-theme) {
  .component {
    @include glass-main-block;
    border-radius: 4px;
  }
}