@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500&family=Roboto:ital,wght@0,100;0,300;1,100;1,300&display=swap');

:global {
  * {
    font-family: 'IBM Plex Sans', sans-serif;
    box-sizing: border-box;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //background: #FFF;
  }

  #root {
    height: 100%;
    //background: #FFF;
  }

  html {
    overflow: hidden;
    //width: 100%;
  }
  body {
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}