@import "src/components/palette.module.scss";

.component {
  display: flex;
  font-size: 11px;
  max-width: 100px;
  text-wrap: balance;
  gap: 5px;
  align-items: center;
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  line-height: 10px;
}

.isActive {
  color: $-brand-color;
}