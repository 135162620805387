@import "src/components/palette.module.scss";

.component {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.profileIcon {
  font-size: 20px;
  color: #fff;
}
