$-grey: #ccc;
$-grey-light: #f0f0f0;


$-brand-color: var(--brand-color, #deb887);
$-header-background-color: var(--header-background-color, #6a8089);
$-button-color: #fff;

$-higlighted-background: $-brand-color;

$-text-additional: var(--text-additional, #aaa);

$-grey-dark: rgba(0,0,0,0.3);


$-layout-background-image: var(--layout-background-image);
// just previous default image example;
//$-layout-background-image: "http://app.gil.com.ua/image_placeholder/header.jpeg";

$-layout-background-color: var(--layout-background-color, #f1f3f6);

$-checkbox-color: $-brand-color;

$-header-background-image: var(--header-background-image);
// just previous default image example
//$-header-background-image: 'http://app.gil.com.ua/image_placeholder/header.jpeg';

$-header-background-image-about-us: $-header-background-image;

$-glass-main: var(--glass-main, 1);
$-glass-item: var(--glass-item, 1);
$-glass-content: var(--glass-content, 1);
$-glass-border-radius: var(--glass-border-radius, 16px);
$-glass-text-color: var(--text-additional, rgba(0, 0, 0, 0.7));
$-glass-margin-bottom: 10px;


// general
  @mixin glass-content {
    padding: 5px 10px;
  }

  @mixin glass-border-radius {
    border-radius: $-glass-border-radius
  }

  @mixin glass-blur {
    backdrop-filter: blur(12px);
  }

  @mixin glass-font-secondary {
    color: $-glass-text-color;
  }

  @mixin glass-box-shadow {
    box-shadow: 0 0 6px rgba(0,0,0,0.1), 0 1px 1px rgba(0,0,0,0.1);
  }
// specific

    // main
    @mixin glass-main-background {
      background: rgb(255,255,255, $-glass-main);
    }

    @mixin glass-bg {
      @include glass-main-background;
      @include glass-box-shadow;
    }

    @mixin glass-main-block {
      @include glass-bg;
      @include glass-border-radius;
      @include glass-blur;
    }

    //item
    @mixin glass-item-background {
      background: rgb(255,255,255, $-glass-item);
    }

    @mixin glass-item-block {
      @include glass-item-background;
      @include glass-border-radius;
      box-shadow: 0 0 6px rgba(0,0,0,0.1), 0 1px 1px rgba(0,0,0,0.1);
    }

    @mixin glass-item-control {
      @include glass-item-background;
      box-shadow: 0 0 6px rgba(0,0,0,0.1), 0 1px 1px rgba(0,0,0,0.1);
      border-radius: calc(#{$-glass-border-radius}/2);
    }





