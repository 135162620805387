.component {
  iframe {
    display: flex;
    min-height: 100%;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    border: 0

  }
}