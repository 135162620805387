.InnerPages {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.InnerPagesContent {
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  overflow: auto;
  flex-direction: column;
}