@import "src/components/palette.module.scss";

.component {

}

.content {
  padding: 10px;
}

.ratingBlock {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
}

.footer {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

:global(.glass-theme) {
  .ratingBlock {
    @include glass-item-control;
  }
}
