.component {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  overflow: auto;
}

.content {
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  flex: 1 1 100%;
}