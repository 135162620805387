@import "src/components/palette.module.scss";

.component {
  //&:global(.MuiFormControl-root) {
  //  margin-bottom: 20px;
  //}
  :global {
    .react-tel-input .form-control {
      width: 100%;
    }
  }
}

.error {
  :global {
    .react-tel-input .form-control,
    .react-tel-input .flag-dropdown {
      border-color: #d32f2f;
    }
  }
}

.errorText {
  color: #d32f2f;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

:global(.glass-theme) {
  .component {
    background: transparent;

    :global(.flag-dropdown) {
      background: transparent;

    }

    input {
      @include glass-item-background;
      background: rgb(255,255,255, 0.2);
    }
  }


}