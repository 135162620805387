@import "src/components/palette.module.scss";

.component {
  display: flex;
  flex-direction: column;
  min-height: 77px;
  width: 100%;
  flex: 1;
}


.aboutWrapper {
  border-bottom: 1px solid #ddd;
  position: relative;

  .navigationHeaader {
    background: transparent;
    position: absolute;
    width: 100%;
    &:before {
      display: none;
    }
  }
}

.item {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 20px;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  &:hover {
    background-color: #eee;
  }
}

.itemContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: auto;
}

//.nameAndIcon {
//  display: flex;
//  flex-direction: row;
//}

.icon {
  display: flex;
  color: #aaa;
  margin-right: 10px;
}

.selectedValue {
  font-size: 14px;
  color: $-text-additional ;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  padding-right: 30px;
}

.titleBlock {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  overflow: auto;
}
