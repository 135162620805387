@import "src/components/palette.module.scss";

.component {

}

:global(.glass-theme) {
  .component {
    @include glass-content;
  }
}