@import "src/components/palette.module.scss";

.monthName {
  text-transform: capitalize;
}


:global(.glass-theme) {
  .monthName {
    @include glass-font-secondary;
    font-weight: 900;
  }
}