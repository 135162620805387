
$-point-color: #555;
$-size: 3px;

:global {
  .loader {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    //align-items: center;
    //align-content: center;
    justify-content: center;
    padding: 10px 20px;
    gap:10px

  }

  .loader__element {
    border-radius: 100%;
    border: $-size solid $-point-color;
    margin: calc($-size*2);
  }

  .loader__element:nth-child(1) {
    animation: preloader .6s ease-in-out alternate infinite;
  }
  .loader__element:nth-child(2) {
    animation: preloader .6s ease-in-out alternate .2s infinite;
  }

  .loader__element:nth-child(3) {
    animation: preloader .6s ease-in-out alternate .4s infinite;
  }


}

