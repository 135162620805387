@import "src/components/palette.module.scss";

.googleButton {
  //opacity: 0;
}


.button {
  display: flex;
  border-radius: 4px;
  //background: transparent;
  justify-content: center;
  align-items: center;
  //border: 1px solid #fff;
  font-size: 18px;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid $-text-additional;
  color: rgba(0, 0, 0, 0.7);
  gap: 5px;
  &:hover {
    //background: rgba(255,255,255,0.5);
  }
}

.icon {
  font-size: 28px;
  margin-top: 1px;
}

:global(.glass-theme) {
  .button {
    border: 1px solid #fff;
    background: rgba(255,255,255,0.9);
    color:$-text-additional;
    &:hover {
      background: rgba(255,255,255,1);
    }
  }
}