@import "src/components/palette.module.scss";

.component {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  max-height: 100%;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  max-height: 100%;
  overflow: auto;
}
.contentBox {
  display: flex;
  flex: 1 1;
}
.contentOverride {
  flex: 1 1 auto;
}

.inputBox {
  padding: 10px 10px 20px;
}



