@import "../../palette.module";

.component {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  height: 100%;
  padding: 20px;
}

.sectionName {
  font-weight: bold;
}

.specialistPreview {
  border: 0;
}
.serviceItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
}

.serviceSummary {
  margin-top: 5px;
  border-top: 1px solid $-grey-dark;
  display: flex;
  justify-content: end;
  padding: 20px;
  font-weight: bold;
}

.button:global(.MuiButton-root) {
  width: 100%;
  margin-bottom: 10px;
}