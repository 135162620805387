@import "src/components/palette.module.scss";

.component {
  padding: 10px 16px 12px;
  border-bottom: 1px solid #eee;
  position: relative;
  overflow: hidden;
  cursor:pointer;
  user-select: none;

  :global {
    .MuiPaper-root {
      border-radius: 0 !important;
      box-shadow: unset;
      border-bottom: 1px solid #eee;
    }
    .MuiFormControlLabel-root {
      width: 100%;
      display: flex;
      overflow: auto;
      .MuiTypography-root {
        max-width: 100%;
        overflow: auto;
      }
    }
  }

  &.disabled {
    position: relative;
    opacity: 0.3;
    cursor: default;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top:0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
}

.notAvailableByOneEmployeeMessageBox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.notAvailableByOneEmployeeMessageContent {
  //background: $-brand-color;
  border-radius: 5px;
  padding: 5px;
  position: relative;
  overflow: hidden;
  text-wrap: wrap;
  text-align: center;
  max-width: 80%;

  span {
    position: relative;
    z-index: 2;
    opacity: 0.8;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $-brand-color;
    opacity: 0.7;
  }
}

.label {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  //padding: 5px 10px;
  //flex-direction: row-reverse;
}

.name {
  font-size: 18px;
  font-weight: 500;
}
.nameText {
  span {
    white-space: initial;
  }

}

.subItem {
  margin-bottom: 10px;
}

.itemImage {
  display: flex;
  justify-content: center;
  img {
    max-width: 100%;
    border-radius: 5px;
    max-height: 100%;
  }
}

.priceAndDuration {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.duration {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 12px;
}


.price {
  font-weight: bold;
  font-size: 14px;
}

:global(.glass-theme) {
  .component {
    @include glass-item-block;
    @include glass-font-secondary;
    margin-bottom: $-glass-margin-bottom;
    border: 0;
  }

  .notAvailableByOneEmployee {
    background: transparent;

  }

  .notAvailableByOneEmployeeMessageBox {
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.1);
    }
  }
}