@import "src/components/palette.module.scss";

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.image img {
  max-width: 100%;
  max-height: 100%;
}

:global(.glass-theme) {
  .component {
    border-radius: $-glass-border-radius;
    overflow: hidden;
  }
}
