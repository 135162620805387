.component {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.noDataWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}