@import "src/components/palette.module.scss";

.component {
  border-top: 1px solid $-grey;
  min-height: 56px !important;
  :global {
    .MuiBottomNavigationAction-root {
      padding: 0;
    }
    .MuiBottomNavigationAction-label {
      font-family: "IBM Plex Sans", sans-serif;
      font-weight: 300;
      font-size: 0.75rem;
    }

    .MuiButtonBase-root.MuiBottomNavigationAction-root.Mui-selected {
      color: $-brand-color;
      .MuiBottomNavigationAction-label.Mui-selected {
        font-size: 0.75rem;
        font-weight: 500;
        color: $-brand-color;
      }
      .MuiSvgIcon-root {
        color: $-brand-color;
      }
    }

  }
}

.icon {
  font-size: 25px;
}

.signUp {
  opacity: 0.8;
}

:global(.glass-theme) {
  .component {
    @include glass-item-block;
    border: 0;
  }

  :global(.isMobile) {
    .component {
      margin: 5px;
    }
  }
}