@import "src/components/palette.module.scss";

.component {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  max-height: 100%;
}

.dateBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  .day {
    font-size: 30px;
    text-transform: capitalize;
  }

  .month {
    font-size: 18px;
  }
}

.content {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}

:global(.glass-theme) {
  .day,
  .month,
  .button {
    @include glass-font-secondary;
    font-weight: 900;
  }
}