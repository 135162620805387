@import "src/components/palette.module.scss";

.timeslots {
  padding: 10px;
}
.timeslotsDate {
  font-size: 14px;
  color: $-text-additional;
}
.timeslotsItems {
  display: flex;
  overflow: auto;

}

// Next strings for hide scrollbar
//  .timeslotsItems {
//    // Next strings for hide scrollbar
//    //scrollbar-width: thin;
//    //scrollbar-color: transparent transparent;
//    //margin-bottom: -10px;
//    //&::-webkit-scrollbar {
//    //  width: 0;
//    //}
//    //&::-webkit-scrollbar-thumb,
//    //&::-webkit-scrollbar-track{
//    //  background-color: transparent; /* Make thumb transparent */
//    //  //border: 1px solid red
//    //}
//  }

:global(.glass-theme) {
  .timeslotsDate {
    @include glass-font-secondary;
  }
}