@import "src/components/palette.module.scss";

:global(.glass-theme) {
  .component {
    @include glass-item-block;
    border: 0;
    margin-bottom: $-glass-margin-bottom;
  }
  .infoIcon,
  .languages{
    @include glass-font-secondary;
  }
}
