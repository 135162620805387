@import "src/components/palette.module.scss";

.component {
  border-bottom: 1px solid $-grey;
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0,0,0,0.05);
  }
}

.link {
  text-decoration: none;
}

.name {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 2px;
  color: rgba(0,0,0,0.7);
  text-decoration: none;
}

.address {
  color: $-text-additional;
  font-size: 14px;
  margin-bottom: 5px;
}

.image {
  height: 290px;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}


:global(.glass-theme) {

  .component {
    @include glass-main-block;
    margin-bottom: $-glass-margin-bottom;
    border: 0;
  }

  .address {
    @include glass-font-secondary;
  }
}