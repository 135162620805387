.component {
  text-align: center;
}

.inputPhone {
  margin-bottom: 20px;
  :global {
    .react-tel-input,
    .react-tel-input .form-control {
      height: 50px;
    }
  }
}