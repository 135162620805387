@import "src/components/palette.module.scss";

.component {
  width: 600px;
  max-width: 100%;
  //border-left: 1px solid $-grey;
  //border-right: 1px solid $-grey;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //max-height: calc(100% - 57px);
  max-height: 100%;
  :global {
    .Routing_component {
      display: flex;
      flex-direction: column;
      flex: 1 1 100%;
      max-height: 100%;
    }
  }
}
.content {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  //max-height: 100%;
  //max-height: calc(100% - 57px);
  overflow: auto;
  position: relative;
}

.footer {
  display: flex;
  flex-direction: column;
  //flex: 1 1 100%;
}

:global(.glass-theme) {
  .component {
    @include glass-main-block;
    overflow: hidden;
  }
  :global(.isMobile) {
    .component {
      border-radius: 0;
    }
  }
}