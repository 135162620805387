@import "src/components/palette.module.scss";

.component {
  padding: 10px;
  border-top: 1px solid $-grey-light;

    &:first-child {
        border-top: none;
    }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.user {
  text-transform: capitalize;
  font-weight: 600;
}

.mark {
  font-size: 14px;
}

.content {
  margin: 5px;
  margin-bottom: 10px;
}

.rightBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}

.date {
  font-size: 12px;
  color: $-grey-dark;
}