@import "src/components/palette.module.scss";

.component {
  display: flex;
  justify-content: space-between;
  padding: 50px 20px;

  .button {
    color: #fff;
  }
}

.content {
  color: #fff;
  font-size: 30px;
}

.button:disabled {
  opacity: 0;
}

:global(.glass-theme) {
  .button {
    @include glass-font-secondary;
    font-weight: 900;
  }
}
