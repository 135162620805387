@import "src/components/palette.module.scss";

.component {
  color: #aaa;

}

.iconWrapper {
  display: flex;
  position: relative;
  cursor: pointer;
  color: inherit;

  &:hover {
    color: $-brand-color;
  }
}

.language {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;

  span {
    background-color: rgba(255,255,255,0.7);
    padding: 2px;
    font-weight: 700;
    border-radius: 50px;
    //color: #aaa;
  }
}

:global(.glass-theme) {

  .component {
    @include glass-font-secondary;
    padding: 3px 5px;
  }

  .language {
    position: relative;
    span {
      background-color: transparent;
      @include glass-font-secondary;
    }
  }

  .icon {
    display: none;
  }
}