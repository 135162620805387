@import "src/components/palette.module.scss";

.component {
  padding: 10px 20px;
  text-align: center;
  margin: 5px;
  border-radius: 10px;
  background-color: rgb(243, 246, 249);
  border: 1px solid rgb(224, 227, 231);
  cursor: pointer;
  &:hover {
    //filter: brightness(0.95);
    background: rgba(231, 235, 240, 0.7);
    border-color: rgb(205, 210, 215);
  }
}

:global(.glass-theme) {
  .component {
    @include glass-item-block;
    @include glass-font-secondary;
    border: 0;
  }
}