@import "src/components/palette.module.scss";

.component {
  //min-height: 50px;
  background-color: $-header-background-color;
  display: flex;
  flex-direction: column;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: $-header-background-image;
    background-size: cover;
    opacity: 0.4;
    z-index: 0;
  }

  & > div {
    position: relative;
    z-index: 10;
  }
}

.title {
  text-align: center;
  color: #fff;

  // for glass theme (in another way it looks not in one line with other controls)
  position: relative;
  top: -1px;
}
.navigation {
  display: flex;
  align-items: center;
  height: 50px;
}

.navigationButton {
  display: flex;
  flex: 1;
  // for back button in French
  min-width: 80px;
  margin: 0 10px;
  button {
    border-color: $-button-color;
    color: $-button-color;
    opacity: 0.8;
  }
  &:hover {
    button {
      border-color: $-button-color;
      color: $-button-color;
      opacity: 1;
    }
  }
}

.navigationButtonPrev {

}

.navigationButtonNext {
  justify-content: end;
}

.rightSide {
  justify-content: end;
  gap: 10px
}

.languageButton {
  color: #fff;
  color: rgba(255,255,255,0.7);
  font-size: 25px;
  div span {
    background-color: transparent;
  }
}

.languageName {
  font-size: 14px;
  color: #fff;
}


:global(.glass-theme) {
  .component {
    border: 0;
    background: transparent;
    &:before {
      display: none;
    }
  }
  .title,
  .navigationButton button{
    font-weight: 900;
    font-family: "IBM Plex Sans", sans-serif;
    color: $-glass-text-color;
    border-color: $-glass-text-color;
    &:hover {
      background: transparent;
    }
  }

  //.navigationButton button {
  //  //@include glass-item-block;
  //  border-radius: 4px;
  //}
}