@import "src/components/palette.module.scss";


.component {
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.list {
  border-top: 1px solid $-grey;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
}

.inputBox {
  display: flex;
  flex: 1 1 auto;
  padding: 10px 10px 10px;
}

.language {
  display: flex;
  justify-content: center;
  align-items: center;
  //padding: 10px 10px 10px 0;
  font-size: 25px;
  margin-right: 10px;
}

:global(.glass-theme) {
  .list {
    padding: 5px 10px;
  }


}
