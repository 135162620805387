.component {
  display: flex;
  flex-direction: column;
  flex: 1 1;

  .preview {
    border: 0;
  }
  .avatar {
    width: auto;
    height: auto;
    max-width: 150px;
    max-height: 150px;
  }
}

.content {
  flex-direction: column;
  display: flex;
  flex: 1 1 auto;
}

.imageBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
}

.image {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
}

