@import "src/components/palette.module.scss";

.component {
  height: 100%;
}

:global(.glass-theme) {
  .component {
    @include glass-content;
  }
}