@import "src/components/palette.module.scss";

.component {
  border-bottom: 1px solid $-grey-light;
  padding: 10px 20px;
  display: flex;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  .avatar {
    margin-right: 10px;
    width: 50px;
    height: 50px;
  }
}

.servicesNames {
  font-weight: 700;
}
.texts {
  padding: 5px 0 ;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
}

.footer {
  display: flex;
  flex-direction: row;
}
.priceBlock {
  width:150px
}

.date {
  color: $-text-additional;
}

:global(.glass-theme) {
  .component {
    @include glass-main-block;
    border: 0;
    margin-bottom: $-glass-margin-bottom;
  }

  .date {
    @include glass-font-secondary;
  }
}