@import "src/components/palette.module.scss";

.component {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  max-height: 100%;
  width: 100%;
}

.anyOption {
  border-bottom: 1px solid $-grey;
  color: $-text-additional;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    position: relative;
    cursor: pointer;
    &:after {
      content:'';
      display: block;
      position: absolute;
      bottom: 0;
      height: 1px;
      width: 100%;
      background-color: $-text-additional;
    }
    &:hover {
      &:after {
        background-color: transparent;
      }
    }
  }
}


:global(.glass-theme) {
  //.component {
  //  @include glass-main-block;
  //  border: 0;
  //}
  //.infoIcon,
  //.languages{
  //  @include glass-font-secondary;
  //}
  .anyOption {
    @include glass-item-block;
    @include glass-font-secondary;
    margin-bottom: $-glass-margin-bottom;

    span:after {
      background-color: $-glass-text-color;
    }
  }

  .content {
    @include glass-content;
  }

}
