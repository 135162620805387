@import "src/components/palette.module.scss";

.component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.name {
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
}

.userName {
  text-transform: capitalize;
  margin-left: 5px;
  font-family: inherit;
}

.buttons {
  display: flex;
  gap: 10px
}
