@import "../../palette.module";

.component {
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.aboutInformation {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.header {
  height: 200px;
  min-height: 200px; // need for flex element
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $-grey;
  background-color: $-header-background-color;
  color: #fff;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: $-header-background-image-about-us;
    background-size: cover;
    opacity: 0.4;
    z-index: 0;
  }
  & > div {
    position: relative;
    z-index: 10;
  }


}

.title {
  font-weight: 400;
  font-family: Roboto, sans-serif;
  font-size: 30px;
}

.content {
  // Refactoring:DESIGN
  //TODO: check this place
  //background: rgb(255, 255, 255, var(--glass-item, 1));
  //border-radius: var(--glass-border-radius, 16px);
  //box-shadow: 0 0 6px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.1);
  border: 0;
  //padding: 20px 30px;
}

.lineItem{
  display: flex;
  margin-bottom: 5px;
}

.lineItemLabel {
  font-weight: bold;
  width: 150px;
  flex-shrink: 0;
}

.lineItemValue {
  color: $-text-additional;
  text-decoration: none;
}

.addressLine {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.addressText {
  display: flex;
}

.mapButton {
  height: 28px;
  flex-shrink: 0;
}

.organizationInformation {
  padding: 20px 10px;
}

.feedbackTitle {
  font-weight: bold;
  padding: 6px 10px;
}

@media only screen and (max-width: 700px) {
  .lineItemLabel {
    font-weight: bold;
    width: 110px;
  }
}

:global(.glass-theme) {
  .lineItemValue {
    @include glass-font-secondary;
  }

  .header {
    background-color: transparent;
    @include glass-font-secondary;
    &:before {
      //display: none;
      opacity: 1;
    }
  }

}