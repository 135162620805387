@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500&family=Roboto:ital,wght@0,100;0,300;1,100;1,300&display=swap');
@import "src/components/palette.module.scss";
@import "src/styles.module.scss";

.component {
  height: 100%;

}

$-test: 'tranparent';

.loadedContentLayout {
  background-color: $-layout-background-color;
  display: flex;
  justify-content: center;
  height: 100%;
  //background-image: linear-gradient(315deg, #e4eee9 0%, #d2dcf3 74%);
  //background-image: linear-gradient(315deg, rgb(228 238 233 / 53%) 0%, rgb(210 220 243 / 37%) 74%);
  background-image: $-layout-background-image;
  background-size: cover;

  //
  //@if $-layout-background-color != 'transparent' {
  //  background-image: none;
  //}
  //@if $-test != 'tranparent' {
  //  background-image: none;
  //  background: red !important;
  //}

}

.spinner {
  background-color: #fff;
}