@import "src/components/palette.module.scss";

.component{
  font-family: 'Rubik', sans-serif;
  min-height: unset;
  height: 20px;
  font-weight: 900;
  text-transform: capitalize;
  padding: 0;
  min-width: 40px;
  content: $-brand-color;

  &:global(.Mui-selected) {
    color: $-brand-color;
  }

}