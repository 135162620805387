@import "src/components/palette.module.scss";

.component {
  height: auto;
  min-height: unset;

  //:global(.MuiTabs-indicator) {
  //  background-color: $-brand-color;
  //}
  :global {
    .MuiTabs-flexContainer {
      gap: 20px;
      padding: 10px 20px;
    }
    .MuiTabs-indicator {
      background-color: $-brand-color;
    }
  }

}