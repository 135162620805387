@import "src/components/palette.module.scss";

.component {
  border-bottom: 1px solid $-grey;
  &.disabled {
    position: relative;
    opacity: 0.3;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top:0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }
}

.generalSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.mainInfo {
  display: flex;
  width: 100%;
  overflow: auto;
  //align-items: center;

  p {
    margin: 0;
  }
  .name {
    max-width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    //display: inline-block;
    font-weight: bold;
    margin-bottom: 3px;
  }
  .languages {
    color: $-text-additional;
    font-size: 14px;
    margin-bottom: 1px;
  }
}

.avatarBox {
  margin-right: 10px;

  :global {
    .MuiAvatar-root {
      width: 55px;
      height: 55px;
    }
  }
}

.additionalInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
}

.ratingBox {
  display: flex;
  align-items: center;
  font-size: 11px;
  .rating {
    font-size: 15px;
  }
}

.infoIcon {
  cursor: pointer;
  color: $-brand-color;
  opacity: 1;
  &:hover {
    opacity: 0.6;
  }
}

.ratingCount {
  color: $-grey-dark;
  margin-left: 3px;
  font-size: 12px;
}

.addFeedbackButton {
  margin-top: 10px;
}


:global(.glass-theme) {
  .component {
    border: 0;
  }
  .infoIcon,
  .languages{
    @include glass-font-secondary;
  }
}
