@import "src/components/palette.module.scss";

.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;

  .icon {
    color: $-text-additional;
    font-size: 50px;
  }
}

.text {
  color: $-text-additional;
}