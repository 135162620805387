@import "src/components/palette.module.scss";

.component {
  .spinner {
    color: $-brand-color;
  }
}

.isFullPage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}