@import "src/components/palette.module.scss";

.component {
  height: 100%;
  display: flex;
  flex-direction: column;
  .submitButton {
    width: 100%;
  }
  .submitButton:not(:disabled) {
    background: $-brand-color;
    &:hover {
      background-color: $-brand-color;
      filter: brightness(1.05);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  .input {
    margin-bottom: 20px;
  }
  .footer {
    display: flex;
    flex-direction: column;
    //margin-top: 20px;
    gap: 10px;
    :global {
      .MuiFormControl-root {
        margin: 0;
        margin-bottom: 10px;
      }
      .MuiFormControlLabel-label {
        display: inline-block;
        word-break: break-all;
        flex: 1 1 100%;
        white-space: initial;
      }
    }
  }
}

.reminderBlock {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.reminderTitle {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  font-size: 14px;
  margin-right: 10px;
}

.reminderSelect {
  width: 100%;
}

.textTermsOfUse {
  white-space: break-spaces;
}

.textTermsOfUse a{
 color: rgb(66 127 231);
}

:global(.glass-theme) {
  .layout {
    &:before{
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top:0;
      width: 100%;
      height: 100%;
      background: rgb(255,255,255, $-glass-main);
    }

    //@include glass-main-block;
    //overflow: hidden;
  }
}