@import "src/components/palette.module.scss";

.component {}

.primary:global(.MuiButton-root) {
  background-color: $-brand-color;
  color: #fff;

  &:hover {
    background-color: $-brand-color;
    filter: saturate(1.1);
  }

}

.text:global(.MuiButton-root) {
  color: $-brand-color;
  text-decoration: underline;
  font-weight: 600;
  border: 1px solid $-brand-color;
  &:hover {
    background-color: transparent;
    color: $-brand-color;
    filter: saturate(1.1);
    text-decoration: none;

  }

}


.secondary:global(.MuiButton-root) {
  background-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  border: 1px solid $-grey;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  color: rgba(0,0,0,0.78);
  padding: 0 7px;
  text-transform: unset;

  &:hover {
    background-color: $-grey-light;
    color: rgba(0,0,0,1);
  }
}

:global(.glass-theme) {
  .secondary {
    @include glass-bg;
  }

}
