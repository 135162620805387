@import "src/components/palette.module.scss";

.component {
  //Refactoring Material-UI styles overrides
  &.focused {
    .textField {
      fieldset {
        border-color: $-brand-color;
      }
    }

    .label {
      color: $-brand-color;
    }
  }

  textarea {
    position: relative;
    z-index: 1;
  }
}


.label {
  position: relative;
  font-size: 14px;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.6);
}

.textField {
  width: 100%;
}

:global(.glass-theme) {
  .component {
    .textField {
      @include glass-item-control;
      fieldset {
        @include glass-item-control;
      }
    }
  }
}