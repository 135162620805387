@import "src/components/palette.module.scss";

.component {
  //border: 1px solid red;
  height: 100%;
  width: 100%;
  & > div,
  & > div > div {
    height: 100%;
    width: 100%;
  }

  & > div > div {
    display: flex;
    justify-content: center;

  }
  :global {
    .react-datepicker__header.react-datepicker__header--custom {
      padding: 0;
    }
    .react-datepicker {
      border: 0;
    }

    .react-datepicker__day--today {
      border: 1px solid;
      border-radius: 0.3rem;
    }

    .react-datepicker__day.react-datepicker__day--disabled.react-datepicker__day--today {
      border: 1px solid;
      border-radius: 50px;
    }

    //.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
    //  //cursor: pointer;
    //  font-size: 30px;
    //  width: 80px;
    //  height: 80px;
    //  display: inline-flex;
    //  align-items: center;
    //  justify-content: center;
    //}

    // flex styles
    .react-datepicker {
      width: 100%;
    }
    .react-datepicker__month-container {
      float: none;
      width: 100%;
    }
    .react-datepicker__month {
      margin: 0;
    }
    .react-datepicker__week,
    .react-datepicker__day-names{
      display: flex;
      justify-content: center;
    }

    .react-datepicker__header {
      background-color: unset;
      border: 0;
    }
    .react-datepicker__day-name {
      text-transform: capitalize;
    }
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
      height: 40px;
      width: 60px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
      //cursor: pointer;
      font-size: 20px;
      width: 60px;
      height: 60px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected{
      background-color: transparent;
      color: #000;
    }
    .react-datepicker__day--highlighted {
      background-color: $-brand-color;
      color: #fff;
    }
  }

}

@media only screen and (max-width: 600px) {
  .component {
    :global {
      .react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
        width: 6vh;
        height: 6vh;
      }
      .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
        width: 6vh;
      }

      .react-datepicker__day-names,
      .react-datepicker__month{
        padding: 0 4px;
      }
    }
  }
}

:global(.glass-theme) {
  .component {
    :global {
      .react-datepicker {
        background-color: transparent;

      }
      .react-datepicker__day {
        @include glass-main-block;

      }

      .react-datepicker__day--disabled {
        color: rgba(0,0,0,0.3);
        opacity: 0.7;
      }

      .react-datepicker__day--today {
        color: #000;
        font-weight: 900;
        opacity: 1;
        background: rgb(255,255,255, 0.5);
      }
    }
  }
}