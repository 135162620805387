.component {
  height: 100%;
  display: flex;
  flex-direction: column;
    justify-content: center;
    align-items: center;
}
.content {
  display: flex;
  flex-direction: row;
  font-size: 40px;
  align-items: center;
}