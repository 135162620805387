
$-button-margin: 10px;

:global {
  .slick-prev,
  .slick-next {
    z-index: 2;

    position: absolute;
    top: 50%;

    display: block;
    transform: translate(0, -50%);

    cursor: pointer;

    //&:hover,
    //&:focus {
    //  color: transparent;
    //  outline: none;
    //  background: transparent;
    //}

    &:hover:before,
    &:focus:before {
      opacity: 1;
    }

    &.slick-disabled:before {
      opacity: 0.25;
    }

    &:before {
      font-family: 'slick';
      font-size: 20px;
      line-height: 1;

      opacity: 0.75;
      color: white;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &.slick-prev {
      left: $-button-margin;

    }

    &.slick-next {
      right: $-button-margin;
    }
  }

  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;

    li {
      position: relative;

      display: inline-block;

      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;

      cursor: pointer;

      button {
        font-size: 0;
        line-height: 0;

        display: block;

        width: 20px;
        height: 20px;
        padding: 5px;

        cursor: pointer;

        color: transparent;
        border: 0;
        outline: none;
        background: transparent;

        &:hover,
        &:focus {
          outline: none;
        }

        &:hover:before,
        &:focus:before {
          opacity: 1;
        }

        &:before {
          font-family: 'slick';
          font-size: 6px;
          line-height: 20px;

          position: absolute;
          top: 0;
          left: 0;

          width: 20px;
          height: 20px;

          content: '•';
          text-align: center;

          opacity: 0.25;
          color: black;

          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        li.slick-active &:before {
          opacity: 0.75;
          color: black;
        }
      }
    }
  }
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  width: 25px;
  height: 25px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 20px;

  z-index: 20;
  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.5);
    //color: transparent;
    outline: none;
    //background: transparent;
  }
}