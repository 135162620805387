@import "src/components/palette.module.scss";


.component {
  color: inherit;

}
.dialogContent {
  padding: 0;
}

.item {
  &:first-child {
    border-top: 0;
  }

  text-transform: capitalize;
  display: flex;
  justify-content: center;
  padding: 15px;
  border-top: 1px solid $-grey;
  cursor: pointer;
  gap: 10px;
  align-items: center;

  &:hover {
    background-color: $-brand-color;
    color: #fff;
  }
}

.selected {
  color: $-brand-color
}


:global(.glass-theme) {
  .component {
    :global(.MuiPaper-root ) {
      @include glass-item-block;
      background-color: #dedede;
    }
    :global(.MuiTypography-root) {
      font-weight: 700;
    }
  }
  .selected {
    font-weight: 700;
  }
}