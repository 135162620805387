@import "src/components/palette.module.scss";

.component {
  :global {
    .MuiPaper-root {
      border-radius: 0 !important;
      box-shadow: unset;
      border-bottom: 1px solid #eee;
    }
  }

  &.disabled {
    position: relative;
    opacity: 0.3;

    .subItem {
      opacity: 1;
    }
  }

}
// disabling category
// can't be overflowed with :before because contrlles will not be available for category accordion and for already selected item in category

//.disabledSummary {
//  position: relative;
//  opacity: 0.3;
//  // disabling category
//  // can't be overflowed with :before because contrlles will not be available for category accordion and for already selected item in category
//  //&:before {
//  //  content: '';
//  //  display: block;
//  //  position: absolute;
//  //  left: 0;
//  //  top:0;
//  //  width: 100%;
//  //  height: 100%;
//  //  z-index: 2;
//  //}
//
//  .expandIcon {
//    position: relative;
//    z-index: 3;
//  }
//}

.accordionSummary {
  position: relative;
}


.categoryNameLine {
  display: flex;
  align-items: center;
  gap: 5px;
}

.labelAvailability {
  font-size: 12px;
  color: $-text-additional;
  position: relative;
  top: 1px;
}

.subItem {
  margin-bottom: 10px;
  padding: 0;
  border-bottom: 0;
}

.subItemLabel {
  padding: 5px 10px;
}

:global(.glass-theme) {
  .component {
    @include glass-item-block;
    @include glass-font-secondary;
    margin-bottom: $-glass-margin-bottom;
    border: 0;
    overflow: hidden;
  }
}