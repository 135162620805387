@import "src/components/palette.module.scss";

.component {
  background: #efefef;
  height: 100%;
  padding-top: 30px;
  :global {
    .MuiTabs-flexContainer {
      justify-content: center;

    }
    .MuiTabs-root {

    }
  }

}

.content {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.formStyles {
  background-color: rgba(255,255,255,0.9);
  //display: inline-flex;
  padding: 20px;
  border-radius: 9px;
  min-width: 300px;
  width: 310px;
}

.formContent {
  display: flex;
  //border: 1px solid red;
  & > div {
    width: 100%;
  }
  :global {
    .MuiFormControl-root {
      width: 100%;
    }
  }
}

.Tabs {
  //color: red;
  :global {
    .MuiTabs-indicator {
      background-color: $-brand-color;;
    }
  }
  .Tab:global(.MuiTab-root.Mui-selected) {
    color: $-brand-color;
  }
}

.socialAuthWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.orSocialTitle {
  display: flex;
  width: 100%;
  align-items: center;
  max-width: 250px;
  gap: 10px;
  color: rgba(0,0,0,0.7);
  margin: 15px 0;
  font-weight: 600;
  span {
    height: 1px;
    display: flex;
    flex: 1 1;
    background: $-text-additional;
  }
}


:global(.glass-theme) {
  .component {
    background: transparent;

    .Tab {
      font-weight: 900;
      &:global(.MuiTab-root.Mui-selected) {
        color: #000;
      }
    }

    .Tabs {
      align-items: center;
      justify-content: center;

      :global(.MuiTabs-indicator) {
        @include glass-item-block;
        border-radius: 16px;
        z-index: 0;
        top: 5px;
        bottom: 5px;
        height: auto;
      }
    }

    :global(.MuiTabs-flexContainer) {

      display: inline-flex;
      padding: 3px 5px;
      position: relative;
      z-index:3;
    }

    :global(.MuiTabs-scroller) {
      @include glass-item-block;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    button {
      overflow: hidden;
      border-radius: 16px;
    }
  }

  .formStyles {
    @include glass-item-block;
  }

  .tabsWrapper {
    display: flex;
    justify-content: center;
  }

  .orSocialTitle {
    color: #fff;
    span {
      background: #fff;
    }
  }
}
