$-tooltip-background-color: rgba(97, 97, 97, 0.92);

:global {
  .tippy-popper .tippy-tooltip .tippy-tooltip-content {
    background-color: $-tooltip-background-color;
  }

  .tippy-popper {
    .tippy-tooltip {
      padding: 0;
      border-radius: 5px;
      background-color: $-tooltip-background-color;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

      .tippy-tooltip-content {
        padding: 4px 8px;
        border-radius: 5px;
        text-align: left;
        color: #fff;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
      }
    }

    &[x-placement^='top'] [x-arrow] {
      border-top-color: $-tooltip-background-color;
    }

    &[x-placement^='right'] [x-arrow] {
      border-right-color: $-tooltip-background-color;
    }

    &[x-placement^='bottom'] [x-arrow] {
      border-bottom-color: $-tooltip-background-color;
    }

    &[x-placement^='left'] [x-arrow] {
      border-left-color: $-tooltip-background-color;
    }
  }
}