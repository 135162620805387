@import "src/components/palette.module.scss";

.component:global {
  &.MuiCheckbox-root.Mui-checked {
    color: $-checkbox-color;
  }

}

.component {
  &:hover {
    :global {
      .MuiTouchRipple-root {
        background: $-checkbox-color;
        opacity: 0.1;
      }
    }
  }
}