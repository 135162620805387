@import "src/components/palette.module.scss";

.component {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
}

.tabs {
    display: flex;
    flex: 1 1;
}

.sortIcon {
    &:hover {
        cursor: pointer;
        color: $-brand-color;
    }
}