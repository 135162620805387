@import "src/components/palette.module.scss";

.component {
  display: flex;
  min-height: 50px;
  justify-content: space-between;
  padding: 10px 20px;

  // moved to bottom
  position: absolute;
  bottom: 0;
  z-index: 10000000000000000;
  width: 100%;
  align-items: center;


  .submitButton {
    background-color: $-brand-color;
    color: #fff;
    &:hover {
      //background-color: inherit;
      background-color: $-brand-color;
      filter: brightness(1.05);
    }
  }
}


// default theme
:global(:not(.glass-theme)) {
  .component {
    border-top: 1px solid #ccc;
    min-height: 56px !important;
    background: #fff;
  }
}

:global(.glass-theme) {
  .component {
    @include glass-bg;
    @include glass-border-radius;
    background: #fff;
    //border-radius: 0;
    overflow: hidden;
    margin: 0 5px;
    width: calc(100% - 10px);
    bottom: 5px;
    //
    //margin: 0;
    //width: 100%;
    //bottom: 0;
  }
}

