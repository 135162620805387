@import "src/components/palette.module.scss";

.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  height: 100%;
  color: $-text-additional;
}


.image {
  font-size: 50px;
}